<template>
    <div class="form-list">
        <el-form ref="_importWarehouseForm" :model="dataSource">
            <el-form-item>
                <el-col :span="21" class="form-title" style="text-align:left">  1、1点击<el-button type="text" @click="DownloadFile()" style="text-decoration:underline;color:#1874CD;cursor:pointer">这里</el-button>下载导入模板 </el-col>
            </el-form-item>
            <el-form-item>
                <!--:beforeUpload="beforeUpload"-->
                <el-upload class="upload-demo"
                            :action="importAction"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :data="upLoadData"
                            multiple
                            :limit="3"
                            :on-exceed="handleExceed"
                            :beforeUpload="beforeUpload"
                            :onSuccess="uploadSuccess"
                            :file-list="fileList"
                            :on-error="uploadError">
                    <el-button size="small" type="primary" :loading="loading" :disabled="loading">{{updateText}}</el-button>
                    <div slot="tip" class="el-upload__tip" style="overflow-y:auto;"><span v-html="tipmsg"></span></div>
                </el-upload>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                importAction: this.Utils.getDomain() + "operations/omsapi/warehouse/warehouseinfo/importwarehouseinfo",
                upLoadData: {
                    TemplateID: ''
                    //cpyId: '123456',
                    //occurTime: '2017-08'
                },
                tipmsg: "",
                fileList: [],
                loading: false,
                updateText:"导入"

            };
        },
        props: {
            dataSource: {}
        },
        mounted() {
            //this.loadTemplateListByBusinessType();
        },
        methods: {
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            // 上传成功后的回调
            uploadSuccess(response, file, fileList) {
                this.uploadTHint(false);
                console.log(response);
                this.tipmsg = response.OperationDesc;
                this.Utils.messageBox(response.OperationDesc, response.IsSuccess ? "success" : "error");
            },
            // 上传前对文件的大小的判断
            beforeUpload(file) {
                this.uploadTHint(true);
                const extension = file.name.split('.')[1] === 'xls';
                const extension2 = file.name.split('.')[1] === 'xlsx';
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!extension && !extension2) {
                    this.Utils.messageBox('上传模板只能是 xls、xlsx格式!', "error");
                    this.uploadTHint(false);
                }
                if (!isLt2M) {
                    this.Utils.messageBox('上传模板大小不能超过 10MB!', "error");
                    this.uploadTHint(false);
                }
                return extension || extension2 && isLt2M;
            },
            // 上传错误
            uploadError(response, file, fileList) {
                this.uploadTHint(false);
                this.Utils.messageBox("上传失败，请重试！", "error");
            },
            DownloadFile: function () {
                this.Utils.export("omsapi/common/downloadtemplate?uploadFileType=700&fileName="+"服务仓库模板"+".xls");
            },
            uploadTHint(value){
                if (value) {
                    this.loading = true;
                    this.updateText = "拼命上传中...";
                }
                else
                {
                    this.loading = false;
                    this.updateText = "导入";
                }
            }
        }
    }
</script>
<style scope>
    input[type="file"] {
        display: none;
    }

    .el-upload-list {
        width: 200px;
    }

    .el-select {
        width: 135px;
    }
</style>